<template>
  <v-card width="313" color="secondaryDark">
    <v-card
      class="pa-2 scroll"
      height="230"
      width="313"
      color="transparent"
      flat
      id="scrollable"
    >
      <label class="font-weight-medium ma-0 pa-0 caption textDark--text"
        >Comment Here</label
      >
      <v-card
        class="mt-12"
        v-if="comments.length == 0"
        flat
        color="transparent"
      >
        <v-card-text class="pa-8 d-flex justify-center align-center"
          >No comments to preview</v-card-text
        >
      </v-card>
      <v-card flat class="mt-2 rounded" color="transparent" width="180">
        <v-card-text
          v-for="(comment, index) in comments"
          :key="index"
          class=" pa-2 ma-0 rounded"
        >
          <v-card-text class="ma-0 pa-0 caption textDark--text">
            {{ comment.date }}
          </v-card-text>
          <v-card height="" class="primary">
            <v-card-text class=" ma-0 pa-1 body-2 textLight--text">
              {{ comment.comment }}
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-card>
    <v-card-text class="primary d-flex flex-row ma-0 form-container pa-2 ">
      <v-text-field
        @keyup.enter="addComment()"
        v-model="comment"
        dense=""
        class="ma-0 pa-0 round"
        background-color="secondaryDark"
        placeholder="Comment Here"
        type="text"
      ></v-text-field>
      <v-row
        no-gutters
        justify="center"
        @click="addComment()"
        align="center"
        class="textOrange mb-1 icon-container"
      >
        <v-icon  color="textLight">mdi-send</v-icon>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.icon-container{
height: 26.5px;
border-top-right-radius: 6px;
border-bottom-right-radius: 6px;
}
.round{
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.scroll {
  overflow-y: auto;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.rounded {
  border-radius: 8px;
}
.form-container {
  height: 45px;
}
</style>

<script>
export default {
  name: "BzComment",

  props: ["comments"],

  data() {
    return {
      comment: "",
    };
  },
  mounted() {
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      var div = document.getElementById("scrollable");
      div.scrollTop = div.scrollHeight - div.clientHeight;
    },
    addComment() {
      this.$emit("addComment", this.comment);
      this.comment = "";
      this.scrollToBottom();
    },
  },
  updated() {
    this.scrollToBottom();
  },
};
</script>

<style></style>
