<template>
  <v-container fluid fill-height>
    <v-card width="100%" min-height="50vh" max-height="50vh">
      <v-app-bar tile flat color="transparent">
        <v-toolbar-title
          class="subtitle-1 secondaryText--text font-weight-light title-container"
        >
          <label class="text-wrap">{{ fileType | stepTitle }}</label>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          v-if="showPreviousSubmission"
          small
          text
          @click="previousSubmission"
        >
          <v-icon small color="primary" class="mx-2">mdi-arrow-left</v-icon>
          <auth-link-button
            small
            title="Previous Submission"
          ></auth-link-button>
        </v-btn>
        <v-btn v-if="showNextSubmission" small text @click="nextSubmission">
          <auth-link-button
            small
            class="textOrange--text mr-2"
            title="Next Submission"
          ></auth-link-button>
          <v-icon small color="textOrange">mdi-arrow-right</v-icon>
        </v-btn>
      </v-app-bar>

      <v-divider></v-divider>
      <v-sheet class="scroll ">
        <!-- Image files Submitted  -->
        <bzImageSubmission
          v-if="fileType === 'Image'"
          :files="files"
        ></bzImageSubmission>

        <!-- Doc Files submitted -->
        <v-card
          flat
          tile
          v-if="fileType === 'Doc' && files.length > 0"
          class="scroll"
        >
          <v-card-text>
            <div class="d-flex flex-column">
              <div class="d-flex pa-4 align-center flex-wrap">
                <div
                  class="d-flex mr-4 mb-4 transparent"
                  v-for="(fileId, index) in files"
                  :key="index"
                >
                  <div class="d-flex align-content-center flex-wrap">
                    <div class="d-flex">
                      <v-card
                        hover
                        class="d-flex flex-wrap"
                        @click="viewDoc(files[index].name, files[index].url)"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              class="pb-2 pr-2"
                              color="primary"
                              size="117"
                              >mdi-file-document</v-icon
                            >
                          </template>
                          <span>{{ files[index].name }}</span>
                        </v-tooltip>
                      </v-card>
                      <div class="d-flex">
                        <v-btn
                          class="d-flex ma-0 pa-0 pr-8 ml-n4 mr-2 pt-12 mt-12"
                          max-width="10"
                          text
                          x-small
                        >
                          <v-icon
                            hover
                            @click="openUrl(files[index].url)"
                            medium
                            color="textDark"
                            >mdi-download</v-icon
                          >
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <!-- Text Viewer-->
        <v-card flat tile v-if="fileType === 'Text'" class="scroll">
          <div class="d-flex"></div>
          <v-card-text>
            <div class="d-flex flex-column">
              <div class="d-flex pa-4 align-center align-center flex-wrap">
                <div class="d-flex mr-4 mb-4 transparent">
                  <p>{{ submittedText }}</p>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-card>
  </v-container>
</template>
<style scoped>
.scroll {
  overflow-y: auto;
}
.title-container {
  word-break: break-all;
  white-space: pre-wrap;
}
</style>
<script>
import AuthLinkButton from "@/auth/components/AuthLinkButton";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import BzImageSubmission from "./BzImageSubmission";
export default {
  name: "BzSubmissionCard",
  components: {
    AuthLinkButton,
    BzImageSubmission,
  },
  data: () => ({
    files: [],
    fileType: "",
    fileTypes: [],
    submittedText: "",
    currentSubmission: "",
    currentSubmissionStep: 0,
    currentSubmissionStepId: "",
  }),
  props: ["title", "previous"],
  computed: {
    ...mapFields("evaluator", ["traineeSubmission", "storedFileTypes"]),
    showPreviousSubmission() {
      return (
        this.currentSubmissionStep > 0 &&
        this.currentSubmissionStep < this.traineeSubmission.length
      );
    },
    showNextSubmission() {
      return (
        this.traineeSubmission.length > 0 &&
        this.currentSubmissionStep !== this.traineeSubmission.length - 1
      );
    },
  },
  created() {
    this.currentSubmissionStepId = this.$route.query.id;
    this.fileType = this.$route.query.fileType;
    (this.fileTypes = this.storedFileTypes), this.fetchSubmittedFiles();
  },
  filters: {
    stepTitle: function(fileType) {
      if (!fileType) return "";
      switch (fileType) {
        case "Text":
          return "Text";
        case "Doc":
          return "Documents";
        case "Image":
          return "Image";
      }
    },
  },
  methods: {
    ...mapActions("admin", {
      fetchFilesUploaded: "fetchFilesUploaded",
    }),
    openUrl(url) {
      window.open(url, "_blank");
    },
    // view Documents without Downloading for Document files
    viewDoc(name, url) {
      if (name.split(".")[1] === "docx") {
        const viewUrl =
          "https://view.officeapps.live.com/op/embed.aspx?src=" + url;
        window.open(viewUrl, "_blank");
      } else {
        window.open(url, "_blank");
      }
    },
    fetchSubmittedFiles() {
      // filter just the one submitted data using the Submission Step Id

      this.currentSubmission = this.traineeSubmission.filter(
        (data) => data.submissionStepId === this.currentSubmissionStepId
      );

      //store Current Index of the submitted Files array for Next/Previous Work
      this.traineeSubmission.forEach((submit, index) => {
        if (submit.submissionStepId === this.currentSubmissionStepId) {
          this.currentSubmissionStep = index;
        }
      });

      if (this.fileType === "Image" || this.fileType === "Doc") {
        this.currentSubmission[0].fileIds.forEach((element, index) => {
          this.fetchFiles(element, index);
        });
      } else {
        this.submittedText = this.currentSubmission[0].text;
      }
    },
    previousSubmission() {
      this.currentSubmissionStepId = this.traineeSubmission[
        this.currentSubmissionStep - 1
      ].submissionStepId;
      this.$emit("changeSubmissionStep", this.currentSubmissionStepId);
      this.fileType = this.fileTypes[this.currentSubmissionStep - 1].fileType;
      this.fetchSubmittedFiles();
    },
    nextSubmission() {
      this.currentSubmissionStepId = this.traineeSubmission[
        this.currentSubmissionStep + 1
      ].submissionStepId;
      this.$emit("changeSubmissionStep", this.currentSubmissionStepId);
      this.fileType = this.fileTypes[this.currentSubmissionStep + 1].fileType;
      this.fetchSubmittedFiles();
    },
    async fetchFiles(element, index) {
      this.files = []; // flush previous data
      try {
        const file = await this.fetchFilesUploaded({ logoId: element });

        this.files.splice(index, 0, file);
      } catch (error) {}
    },
  },
};
</script>

<style>
.scroll {
  overflow: hidden;
}
</style>
