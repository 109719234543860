<template>
  <div>
    <admin-header :title="'Submission'" :links="bcLinks"></admin-header>
    <v-container fluid fill-height>
      <v-btn @click="goBack()" class="ma-0 pa-0" small text>
        <v-icon small color="primary">mdi-arrow-left</v-icon>
        <auth-link-button small title="Back"></auth-link-button>
      </v-btn>
      <v-row no-gutters class="mr-12">
        <v-col lg="7" sm="9" cols="9">
          <bz-submission-card
            @changeSubmissionStep="changeStep($event)"
            v-if="title && traineeSubmission.length > 0"
            :title="title"
            :formatTypes="fileTypes"
          ></bz-submission-card>
        </v-col>
        <v-col lg="5" sm="7" cols="8">
          <bz-comment @addComment="addComment($event)" class="mt-3 ml-3" :comments="comments"></bz-comment>
        </v-col>
      </v-row>
      <!-- <v-footer height="65" absolute class="d-flex justify-center">
        <div class="d-flex flex-row">
          <p
            class="body-1 textDark--text mr-12 my-2"
          >Click on Evaluate Submission to rate the selected user</p>
          <BzButton title="Evaluate Submission" depressed width="240" @click="navigateToEvaluate()"></BzButton>
        </div>
      </v-footer> -->
      <!-- <bz-footer
        text=" Click on Rate Submission to rate the selected user"
        buttonTitle="Evaluate Submission"
      ></bz-footer>-->
    </v-container>
  </div>
</template>
<script>
import AuthLinkButton from "@/auth/components/AuthLinkButton";
import BzSubmissionCard from "@/beez-modules/beez-evaluator/components/BzSubmissionCard.vue";
import BzComment from "@/beez-modules/beez-evaluator/components/BzComment.vue";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import moment from "moment";
import AdminHeader from "@/beez-admin/components/AdminHeader";
import BzButton from "@/shared/components/BzSubmitButton";

export default {
  name: "SubmissionList",
  components: {
    BzSubmissionCard,
    BzComment,
    AuthLinkButton,
    AdminHeader,
    BzButton
  },
  computed: {
    ...mapFields("evaluator", ["Trainees", "traineeSubmission"]),
    bcLinks() {
      return [
        {
          text: "Elevation Module",
          disabled: true
        },
        {
          text: "Trainee",
          disabled: true
        },
        {
          text: "Submission",
          disabled: true
        }
      ];
    }
  },
  created() { 
    this.submissionTypeTitle(this.$route.query.fileType);
    this.userId = this.$route.query.userId;
    this.submissionId = this.$route.query.id;
    this.getCourseSubmissions();
    this.getComment();
  },
  data() {
    return {
      submissionId: null,
      traineeSubmissions: null,
      comment: "",
      title: null,
      userId: "",
      comments: [],
      courseDetail: null,
      tempSubmissionStepsId: "",
      fetchedSubmission: null,
      fileType: "",
      fileTypes: []
    };
  },
  filters: {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async changeStep(id) {
      this.submissionId = id;
      await this.getComment();
    },
    ...mapActions("evaluator", {
      getComments: "getComments",
      addComments: "addComments",
      getSubmissionFiles: "getSubmissionFiles"
    }),
    navigateToEvaluate() {
      this.$router.push({
        name: "evaluate-trainee",
        query: {
          i: this.submissionId
        }
      });
    },
    submissionTypeTitle(fileType) {
      switch (fileType) {
        case "Text":
          this.title = "Text";
          break;
        case "Doc":
          this.title = "Documents";
          break;
        case "Image":
          this.title = "Image";
          break;
        default:
          this.title = "Title";
      }
    },
    async getCourseSubmissions() {
      try {
        const Submissions = await this.getSubmissionFiles();

        this.courseDetail = this.Trainees.find(
          element => element.userId === this.userId
        );

        const { submissions } = this.courseDetail;
        // specific SubmissionFile Id
        // if the submission is text just Display it
        this.fetchedSubmission = submissions.find(
          element => element.submissionStepId === this.tempSubmissionStepsId
        );
      } catch (error) {}
    },

    async getComment() {
      this.comments = [];
      try {
        const commentData = await this.getComments({
          submissionId: this.submissionId
        });
        let createdDate = "";
        commentData.map(element => {
          createdDate = moment(element.createdAt).format("L");
          this.comments.push({
            date: createdDate,
            comment: element.comment
          });
        });
      } catch (error) {}
    },
    async addComment(comment) {
      try {
        if (comment !== "" && this.submissionId !== null) {
          // let createdDate = moment(response.createdAt).format("L");
          this.comments.push({
            date: moment().format("L"),
            comment: comment
          });
          await this.addComments({
            submissionId: this.submissionId,
            comment: comment
          });
        }
      } catch (error) {}
    }
  }
};
</script>

<style></style>
