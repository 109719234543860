<template>
  <v-row class="px-2 gallery-container">
    <v-col cols="12" md="6" lg="4" v-for="(image, i) in images" :key="i">
      <v-card class="image" elevation="1" @click="selectImage(i)">
        <v-img :src="image.url" height="150" width="100%"></v-img>
        <v-card-actions class="px-1 py-0">
          <span class="caption primary--text">{{ image.name }}</span>
          <v-icon color="textOrange" class="ml-auto">mdi-fullscreen</v-icon>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-dialog v-model="dialog" width="fit-content" content-class="elevation-0">
      <div>
        <img :src="images[selected].url" class="large-image" />
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["files"],
  data: () => ({
    selected: 0,
    dialog: false,
  }),
  computed: {
    images() {
      const images = [];
      if (this.files.length > 0) {
        this.files.forEach((file) => {
          if (
            file.contentType.substr(0, file.contentType.lastIndexOf("/")) ==
            "image"
          ) {
            images.push({
              name: file.originalName,
              url: file.url,
            });
          }
        });
      }

      return images;
    },
  },
  methods: {
    selectImage(i) {
      this.selected = i;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.large-image {
  max-width: 800px;
  max-height: 600px;
}
.gallery-container {
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.image:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: 0.5s;
}
</style>
